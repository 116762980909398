
import QualifyFilesModal from "@/components/QualifyFilesModal.vue";

export default {
  name: "QualifiedRequests",
  components: { QualifyFilesModal },
  computed: {
    zipArchiveUrl() {
      return this.loadZipArchive();
    }
  },
  methods: {
    approve(accountDto) {
      this.axios
        .patch(
          `${process.env.VUE_APP_BACKEND_BASE_URL}/accounts/${accountDto.uid}/qualified`,
          {
            qualified: true,
            accountId: accountDto.uid,
          }
        )
        .then((_) => {
          this.load();

          this.snackbarMessage = `Аккаунт ${accountDto.uid} помечен как квалифицированный`;
          this.snackbar = true;
        });
    },
    closeModal() {
      this.showModal = false;
      this.selectedAccount = undefined;
    },
    decline(accountDto) {
      this.axios
        .patch(
          `${process.env.VUE_APP_BACKEND_BASE_URL}/accounts/${accountDto.uid}/qualified`,
          {
            qualified: false,
            accountId: accountDto.uid,
          }
        )
        .then((_) => {
          this.load();

          this.snackbarMessage = `Аккаунт ${accountDto.uid} помечен как не квалифицированный`;
          this.snackbar = true;
        });
    },
    showFiles(accountDto) {
      this.axios
        .get(
          `${process.env.VUE_APP_BACKEND_BASE_URL}/files/qualify/${accountDto.id}/files`
        )
        .then((res) => {
          console.log(res.data);
          this.showModal = true;
          this.names = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.names = [];
        })
        .finally(() => (this.loading = false));
    },
    load() {
      this.accountDtos = this.axios
        .get(`${process.env.VUE_APP_BACKEND_BASE_URL}/files/qualify`)
        .then((res) => {
          console.log(res.data);
          this.accountDtos = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.accountDtos = [];
        })
        .finally(() => (this.loading = false));
    },
    async loadZipArchive() {
      return await this.axios.get(`${process.env.VUE_APP_BACKEND_ACCOUNT_URL}/files/download/qualFiles`, {responseType: "blob"})
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', `qualArchive.zip`);
            document.body.appendChild(link);
            link.click();
          });
    },
    async loadSmltZipArchive() {
      return await this.axios.get(`${process.env.VUE_APP_BACKEND_ACCOUNT_URL}/files/download/smltSharesReturn`, {responseType: "blob"})
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', `smltSharesReturnArchive.zip`);
            document.body.appendChild(link);
            link.click();
          });
    },
  },
  mounted() {
    this.accountDtos = this.axios
      .get(`${process.env.VUE_APP_BACKEND_BASE_URL}/files/qualify`)
      .then((res) => {
        console.log(res.data);
        this.accountDtos = res.data;
      })
      .catch((err) => {
        console.error(err);
        this.accountDtos = [];
      })
      .finally(() => (this.loading = false));
  },
  data() {
    return {
      loading: true,
      timeout: 2000,
      showModal: false,
      snackbar: false,
      snackbarText: "",
      accountDtos: [],
      names: [],
    };
  },
};
